import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from '../pages/login/login.action';
import clientsReducer from '../pages/client-list/clientList.action';
import searchReducer from '../pages/search/search.action';
import clientReducer from '../pages/client/client.action';
import noticesReducer from '../pages/notices/notices.action';
import systemReducer from '../pages/main/main.action';
import featureReducers from '../features';

const rootReducer = combineReducers({
  login: loginReducer,
  clients: clientsReducer,
  search: searchReducer,
  client: clientReducer,
  notices: noticesReducer,
  system: systemReducer,
  ...featureReducers,
});

export default rootReducer;
