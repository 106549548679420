import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import './index.css';
import App from './App';
import { SWInit, SWUpdate } from './pages/main/main.action';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register({
  onSuccess: () => store.dispatch(SWInit()),
  onUpdate: (reg) => {
    store.dispatch(SWUpdate(reg));
  },
});
