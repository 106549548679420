/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import api from '../../rest/rest';

const initialState = {
  results: [],
  query: '',
  loading: false,
};

const clients = createSlice({
  name: 'search',
  initialState,
  reducers: {
    getSearchStart(state) {
      state.loading = true;
    },
    getSearchSuccess(state, action) {
      state.results = action.payload.results;
      state.query = action.payload.query;
      state.loading = false;
      state.error = null;
    },
    getSearchError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resetSearch(state, action) {
      state.results = [];
      state.query = '';
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  getSearchStart,
  getSearchSuccess,
  getSearchError,
  resetSearch,
} = clients.actions;

export default clients.reducer;

export const resetSearchResult = () => (dispatch) => dispatch(resetSearch());

export const getSearch = (query) => async (dispatch) => {
  try {
    dispatch(getSearchStart());
    const results = await api.get(`/search?query=${query}`);

    dispatch(getSearchSuccess({ results, query }));
  } catch (err) {
    dispatch(getSearchError(err));
  }
};
