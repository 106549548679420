/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  noticeBadges: {
    notice: false,
  },
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
  theme: localStorage.getItem('theme') || 'light',
};

const system = createSlice({
  name: 'system',
  initialState,
  reducers: {
    getNoticeBadgesSuccess(state, action) {
      state.noticeBadges.notice = action.payload.reserve;
    },
    SWInit(state, action) {
      state.serviceWorkerInitialized = !state.serviceWorkerInitialized;
    },
    SWUpdate(state, action) {
      state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
      state.serviceWorkerRegistration = action.payload;
    },
    setTheme(state, action) {
      state.theme = action.payload;

      localStorage.setItem('theme', action.payload);
    },
  },
});

export const {
  getNoticeBadgesSuccess,
  SWInit,
  SWUpdate,
  setTheme,
} = system.actions;

export default system.reducer;

export const getNoticeBadges = () => async (dispatch, getState, core) => {
  try {
    const data = await core.api.get('/notice/active');

    dispatch(getNoticeBadgesSuccess(data));
  } catch (err) {
    console.error(err);
  }
};
