const errors = {
  401: 'Ошибка авторизации',
  403: 'У вас нет прав для данного запроса',
  409: 'Данное действие закреплено за другим менеджером',
  429: 'Слишком много запросов',
  500: '500 Ошибка сервера',
  504: '504 Сервер недоступен',
};

export default errors;
