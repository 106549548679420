import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import { closePreview } from './imageViewer.action';
import 'react-image-lightbox/style.css';

// const staticUrl = process.env.REACT_APP_STATIC_URL;

export default function AttachmentsViewer() {
  const dispatch = useDispatch();
  const attachViewer = useSelector((state) => state.viewer);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setPhotoIndex(attachViewer.currentImage);
    setImages(attachViewer.images);
  }, [attachViewer]);

  function handlePrev() {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  }

  function handleNext() {
    setPhotoIndex((photoIndex + 1) % images.length);
  }

  const path = '';

  // if (images[(photoIndex + 0) % images.length]) {
  //   path = images[(photoIndex + 0) % images.length].includes('https') ? '' : staticUrl;
  // }

  return attachViewer.open ? (
    <Lightbox
      mainSrc={`${path}${images[(photoIndex + 0) % images.length]}`}
      nextSrc={`${path}${images[(photoIndex + 1) % images.length]}`}
      prevSrc={`${path}${
        images[(photoIndex + images.length - 1) % images.length]
      }`}
      onMovePrevRequest={handlePrev}
      onMoveNextRequest={handleNext}
      onCloseRequest={() => dispatch(closePreview())}
    />
  ) : null;
}
