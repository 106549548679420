/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  images: [],
  currentImage: 0,
  open: false,
};

const viewer = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    openViewer(state, { payload }) {
      state.images = payload.images;
      state.currentImage = payload.currentImage;
      state.open = true;
    },
    closeViewer(state) {
      state.images = [];
      state.currentImage = 0;
      state.open = false;
    },
  },
});

export default viewer.reducer;

export const { openViewer, closeViewer } = viewer.actions;

export const getPreview = (imgUrl, images) => (dispatch) => {
  try {
    dispatch(
      openViewer({
        images: images || [imgUrl],
        currentImage: images ? images.indexOf(imgUrl) : 0,
      }),
    );
  } catch (err) {
    throw new Error(err);
  }
};

export const closePreview = () => (dispatch) => dispatch(closeViewer());
