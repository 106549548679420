import React, { useEffect } from 'react';
import { Badge, Dropdown, Layout, Menu } from 'antd';
import { createTeleporter } from 'react-teleporter';
import { MenuOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import dark from 'antd/dist/dark-theme';
import light from 'antd/dist/default-theme';
import history from '../../store/history';
import { logOut } from '../../pages/login/login.action';
import { setTheme } from '../../pages/main/main.action';

export const HeaderPortal = createTeleporter();

const { Header } = Layout;

export default function MainHeader(props) {
  const { noticeBadges, theme } = useSelector((state) => state.system);
  const { access } = useSelector((state) => state.login.user);

  const dispatch = useDispatch();

  useEffect(() => {
    window.less.modifyVars(theme === 'dark' ? dark : light);
  }, [theme]);

  async function changeTheme() {
    dispatch(setTheme(theme === 'light' ? 'dark' : 'light'));
  }

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => history.push('/')}
        style={{ padding: '12px 24px' }}>
        Главная
      </Menu.Item>
      {access['Магазины'] === 15 && (
        <Menu.Item
          onClick={() => history.push('/notices')}
          style={{ padding: '12px 24px' }}>
          <Badge dot={noticeBadges.notice}>Уведомления</Badge>
        </Menu.Item>
      )}
      <Menu.Item disabled style={{ padding: '12px 24px' }}>
        Профиль
      </Menu.Item>
      <Menu.Item onClick={changeTheme} style={{ padding: '12px 24px' }}>
        {theme === 'dark' ? 'Светлая ' : 'Темная '}
        тема
      </Menu.Item>
      <Menu.Item
        onClick={() => dispatch(logOut)}
        style={{ padding: '12px 24px' }}>
        Выйти
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      style={{
        display: 'flex',
        background: theme === 'dark' ? '#1b1b1b' : '#ffe3c8',
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        padding: 0,
        justifyContent: 'space-between',
        height: '48px',
        lineHeight: '48px',
      }}>
      <HeaderPortal.Target style={{ width: '100%' }} />
      <Dropdown overlay={menu} trigger={['click']}>
        <MenuOutlined
          style={{
            color: 'inherit',
            marginRight: 10,
            fontSize: 24,
            alignSelf: 'center',
            padding: '8px',
          }}
        />
      </Dropdown>
    </Header>
  );
}
