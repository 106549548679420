import React from 'react';
import {
  UsergroupAddOutlined,
  CarryOutOutlined,
  BellOutlined,
  SkinOutlined,
  FrownOutlined,
  TagOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
  SearchOutlined,
} from '@ant-design/icons';

export const routingTop = [
  {
    route: '/list',
    icon: <UsergroupAddOutlined style={{ fontSize: 24 }} />,
    title: 'Клиенты',
  },
  {
    route: '/list2',
    icon: <SkinOutlined style={{ fontSize: 24 }} />,
    title: 'Товары',
  },
  {
    route: '/list3',
    icon: <CarryOutOutlined style={{ fontSize: 24 }} />,
    title: 'Заказы',
  },
  {
    name: 'notice',
    route: '/notices',
    icon: <BellOutlined style={{ fontSize: 24 }} />,
    title: 'Нотис',
  },
  {
    route: '/search',
    icon: <SearchOutlined style={{ fontSize: 24 }} />,
    title: 'Поиск',
  },
];

export const routingClient = [
  {
    route: (params) => `/client/${params.type}/`,
    link: (params) => `/client/fitting/${params.id}`,
    icon: <TagOutlined style={{ fontSize: 24 }} />,
    title: 'Примерки',
  },
  {
    route: '/client/fitting2',
    link: (params) => `/client/fitting2/${params.id}`,
    icon: <ShoppingCartOutlined style={{ fontSize: 24 }} />,
    title: 'Покупки',
  },
  {
    route: '/client/fitting3',
    link: (params) => `/client/fitting3/${params.id}`,
    icon: <DollarOutlined style={{ fontSize: 24 }} />,
    title: 'Бонусы',
  },
  {
    route: '/client/fitting4',
    link: (params) => `/client/fitting4/${params.id}`,
    icon: <FrownOutlined style={{ fontSize: 24 }} />,
    title: 'Отказы',
  },
  {
    route: '/search',
    icon: <SearchOutlined style={{ fontSize: 24 }} />,
    title: 'Поиск',
  },
];
