import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import rootReducer from './rootReducer';
import api from '../rest/rest';
import history from './history';

export const core = { api, notification, message, history };

const customizedMiddleware = getDefaultMiddleware({
  thunk: {
    extraArgument: core,
  },
  serializableCheck: true,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [...customizedMiddleware],
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = rootReducer.default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;
