import { notification } from 'antd';
import errorStatuses from './errorStatuses';

const apiUrl = process.env.REACT_APP_API_URL || '';

class Api {
  /**
   * Check status response
   * @param {object} response data of response
   * @return {Promise.<TResult>}
   */
  static resStatus(response) {
    const { status } = response;

    if (status >= 200 && status < 300) {
      return Promise.resolve(response, status);
    }

    return Promise.reject(response);
  }

  /**
   * Convert response to json
   * @param {object} res response
   * @returns {object}
   */
  static async json(res) {
    try {
      return await res.json();
    } catch (err) {
      console.warn('ERP: The response can`t possible to convert to json', err);
      return false;
    }
  }

  static async errorHandler(res) {
    let body = 'oops! unknown error!';
    if (errorStatuses[res.status]) {
      console.error('Ой!', errorStatuses[res.status]);
      body = errorStatuses[res.status];
    }

    try {
      body = await res.json();
    } catch (error) {
      // console.error('Ой!', body);
    }

    if (res.status !== 401) {
      notification.error({
        message: 'Ошибка!',
        // description: JSON.stringify(body) || 'Не обработанное исключение',
        description: 'Не обработанное исключение',
        placement: 'bottomLeft',
      });
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status: res.status,
      body,
    });
  }

  /**
   * Get token with localstorage
   * @returns {string}
   */
  static getToken() {
    return localStorage.getItem('USER_KEY') || '';
  }

  /**
   * Get rest options from rest request
   * @param {object} body body from responce
   * @param {string} method type of method
   * @returns {object} options from rest
   */
  getOptions(body, method) {
    return {
      method,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': this.constructor.getToken(),
      },
      body: body ? JSON.stringify(body) : undefined,
    };
  }

  /**
   * Getter token with localstorage
   * @returns {string}
   */
  get token() {
    return this.constructor.getToken();
  }

  /**
   * Multiple fetch wrapper
   * @param {Array} promises
   * @return {Promise.<TResult>}
   */
  multi(promises) {
    return Promise.all(promises).catch(this.errorHandler);
  }

  /**
   *
   * @param {string} name type response
   * @returns {function}
   */
  sendResponce(name) {
    /**
     * Get fetch wrapper
     * @param {string} url url
     * @param {object} body url
     * @returns {Promise.<TResult>}
     */
    return (url = '', body, options) => {
      return fetch(
        `${apiUrl}${url}`,
        options ? { method: name, ...options } : this.getOptions(body, name),
      )
        .then(this.constructor.resStatus)
        .then(this.constructor.json)
        .catch(this.constructor.errorHandler);
    };
  }

  get = this.sendResponce('get');

  post = this.sendResponce('post');

  put = this.sendResponce('put');

  update = this.sendResponce('update');

  delete = this.sendResponce('delete');
}

export default new Api();
