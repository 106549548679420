import { createSlice } from '@reduxjs/toolkit';

const postOptions = (body = {}, method = 'post') => ({
  method,
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

const user = {
  apiKey: localStorage.getItem('USER_KEY'),
  username: localStorage.getItem('USER_NAME'),
  userId: localStorage.getItem('USER_ID'),
  access: null,
};

const initialState = {
  user,
};

const login = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedIn(state, action) {
      state.user = { ...action.payload };
    },
    userLoggedOut(state) {
      state.user = null;
    },
    userAccess(state, action) {
      const access = action.payload.reduce((acc, {resource, permission}) => ({ ...acc, [resource]: permission }), {});
      state.user.access = access;
    }
  },
});

export const { userLoggedIn, userLoggedOut, userAccess } = login.actions;

export default login.reducer;

export const getUserAccess = () => async (dispatch, getState, core) => {
  try {
    const access = await core.api.get('/access-user');
    dispatch(userAccess(access));
    return access;
  } catch (err) {
    console.error(err);
    if (err.status === 401) {
      core.history.push('/login');
    }
  }
}


export const logIn = (loginParam) => async (dispatch, getState, core) => {
  try {
    const user = await core.api.post('/login', null, postOptions(loginParam));
    
    if (user.apiKey) {
      localStorage.setItem('USER_ID', user.userId);
      localStorage.setItem('USER_NAME', user.username);
      localStorage.setItem('USER_KEY', user.apiKey);
    }
    
    if (user.apiKey) {
      dispatch(userLoggedIn(user));
      core.history.push('/');
    }

  } catch (err) {
    if (err.status === 401) {
      core.notification.error({
        message: 'Ошибка!',
        description: 'Логин или пароль введены не верно!',
        placement: 'topLeft',
      });
    }
    dispatch(userLoggedOut());
  }
};

export const logOut = async (dispatch, getState, core) => {
  localStorage.removeItem('USER_ID');
  localStorage.removeItem('USER_NAME');
  localStorage.removeItem('USER_KEY');

  dispatch(userLoggedOut());

  core.history.push('/login');
};
