import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { HeaderPortal } from './Header';
import history from '../../store/history';
import styles from './HeaderPortal.module.less';

HeaderPortalSource.propTypes = {
  backButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

HeaderPortalSource.defaultProps = {
  subtitle: '',
  backButton: false,
};

export default function HeaderPortalSource({ backButton, title, subtitle }) {
  return (
    <HeaderPortal.Source>
      <div
        style={{
          display: 'flex',
          height: '100%',
          padding: '0 10px',
        }}>
        {backButton && (
          <Button
            type="link"
            onClick={() => history.push('/list')}
            style={{ alignSelf: 'center', margin: '0 8px' }}
            icon={<ArrowLeftOutlined style={{ fontSize: 24 }} />}
          />
        )}
        <h4
          className={styles.title}
          style={{
            alignSelf: 'center',
            fontSize: '16px',
            lineHeight: '1em',
            marginBottom: 0,
          }}>
          {title}
          {subtitle && (
            <>
              <br />
              <span style={{ fontSize: 12 }}>{subtitle}</span>
            </>
          )}
        </h4>
      </div>
    </HeaderPortal.Source>
  );
}
