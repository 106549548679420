import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Camera from '@2fd/ant-design-icons/lib/Camera'
import FolderImage from '@2fd/ant-design-icons/lib/FolderImage'
import { Card, Modal, Spin, notification } from 'antd';
import styles from '../../components/mainMenu/mainMenu.module.css';
import api from '../../rest/rest'; 

const { Meta } = Card;

MainMenu.propTypes = {
  camera: PropTypes.bool.isRequired,
}

export default function MainMenu(props) {
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const input = useRef(null);

  const handleOpen = () => {
    input.current.value = null;
    input.current.click();
  };

  const handleInput = e => {
    if(e.target.value) {
      const reader = new FileReader();

      reader.onload = event => {
        setFile({fileName: input.current.files[0].name, src: event.target.result});
        setIsModalVisible(true);
      };

      reader.onerror = () => notification.error({
        message: 'Ошибка!',
        description: 'Ошибка при обратке фото',
        placement: 'bottomLeft',
      });

      reader.readAsDataURL(input.current.files[0]);
    }
  }

  const handleUploadImage = async (data) => {
    setLoading(true);

    try {
      await api.post('/shooting-upload', { fileName: data.fileName, data: data.src });

      notification.success({
        message: 'Успех!',
        description: 'Фото успешно загружено',
        placement: 'bottomLeft',
      });

      setIsModalVisible(false);
      setFile('');

    } catch (error) {
      console.error(error);
    }
    setLoading(false);

  };

  const handleCancelModal = (e) => {
    e.preventDefault();
    setIsModalVisible(false)
  };

  return (
    <>
      <Modal
        closable={false}
        maskClosable={false}
        title="Отправить фото?"
        visible={isModalVisible}
        onOk={() => handleUploadImage(file)}
        okButtonProps={{ disabled: loading }}
        onCancel={handleCancelModal}
      >
        <Spin spinning={loading}>
          {file && (
            <img 
              style={{
                width: '100%',
                maxWidth: '450px'
              }} 
              src={file.src} 
              alt="img" />
          )}
        </Spin>
      </Modal>
      <div className={styles.link} onClick={handleOpen}>
        <Card
          className={styles.card}
          cover={
            <span>
              {props.camera 
                ? <Camera className={styles.icon} />
                : <FolderImage className={styles.icon} />}
            </span>
          }>
          <Meta 
            title={props.camera ? 'Камера' : 'Галерея'}
            description={props.camera ? 'Сделать снимок с камеры' : 'Выбрать фото из галереи'} />
          
          <input 
            style={{ display: 'none' }}
            onChange={handleInput}
            ref={input} 
            type="file" 
            accept="image/*" 
            capture={props.camera ? 'camera' : null} />
        </Card>
      </div>
    </>
  );
}
