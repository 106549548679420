/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import api from '../../rest/rest';

const initialState = {
  entities: null,
  limit: 20,
  offset: 0,
  sort: 'ASC',
  loading: false,
  loadingScroll: false,
  error: null,
  totalCount: 0,
};

const clients = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getListStart(state) {
      state.loading = true;
    },
    getListLoading(state) {
      state.loadingScroll = true;
    },
    getListSuccess(state, action) {
      state.entities = action.payload.entities;
      state.loading = false;
      state.limit = 10;
      state.offset = action.payload.entities.length;
      state.error = null;
      state.totalCount = action.payload.totalCount;
    },
    getListError(state, action) {
      state.loading = false;
      state.loadingScroll = false;
      state.error = action.payload;
    },
    pushList(state, action) {
      state.entities = [...state.entities, ...action.payload];
      state.offset += action.payload.length;
      state.loadingScroll = false;
    },
  },
});

export const {
  getListStart,
  getListSuccess,
  getListError,
  pushList,
  getListLoading,
} = clients.actions;

export default clients.reducer;

export const getClientsList = (limit = 10, offset = 0, sort) => async (
  dispatch,
) => {
  try {
    dispatch(getListStart());

    const [entities, { count }] = await Promise.all([
      api.get(`/clients-opened/list?limit=${limit}&offset=${offset}`),
      api.get('/clients-opened/count'),
    ]);

    dispatch(
      getListSuccess({ entities, limit, offset, sort, totalCount: count }),
    );
  } catch (err) {
    dispatch(getListError(err));
  }
};

export const getNextPageClientList = () => async (dispatch, getState, core) => {
  try {
    const { limit, offset, totalCount } = getState().clients;
    dispatch(getListLoading());

    const result = await core.api.get(
      `/clients-opened/list?limit=${limit}&offset=${offset}`,
    );

    if (totalCount === offset + result.length) {
      core.message.success('весь список загружен');
    }

    dispatch(pushList(result));
  } catch (err) {
    dispatch(getListError(err));
  }
};
