/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import history from './store/history';

import './App.less';
import MainMenu from './components/mainMenu';
import MobileNavBar from './components/mobileNavBar';
import { routingTop, routingClient } from './routingNavBar';
import ImageViewer from './features/imageViewer/ImageViewer';
import asyncComponent from './utils/asyncComponent';
import NotificationSW from './components/notificationSW';
import { getUserAccess } from './pages/login/login.action';

const AsyncLoginPage = asyncComponent(() => import('./pages/login'));
const AsyncWip = asyncComponent(() => import('./pages/wip'));
const AsyncMainPage = asyncComponent(() => import('./pages/main'));
const AsyncSearchPage = asyncComponent(() => import('./pages/search'));
const AsyncClientListPage = asyncComponent(() => import('./pages/client-list'));
const AsyncClient = asyncComponent(() => import('./pages/client'));
const AsyncNotices = asyncComponent(() => import('./pages/notices'));

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

function PrivateRoute({ children, user }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.apiKey && !user.access) {
      dispatch(getUserAccess());
    }
  }, [user, dispatch]);

  if (user.apiKey && !user.access) {
    return 'loading...'
  }

  return (
    <Route
      render={({ location }) =>
        (user?.apiKey && user?.access) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

App.propTypes = {};

function App(props) {
  const { user } = useSelector((state) => state.login);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login">
          <AsyncLoginPage />
        </Route>

        <PrivateRoute user={user}>
          <>
            {user?.access?.['Магазины'] === 15 && (
              <>
                <NotificationSW />
                <ImageViewer />
              </>
              )}
            <Switch>
              {user?.access?.['Магазины'] === 15 && (
                <Route
                  path="/:type"
                  render={(location) => (
                    <AsyncMainPage>
                      <MobileNavBar {...location} routing={routingTop}>
                        <Switch>
                          <Route path="/list" component={AsyncClientListPage} />
                          <Route
                            path="/list2"
                            render={() => (
                              <AsyncWip
                                title="Бронь"
                                subtitle="Список бронь на подтверждение"
                              />
                            )}
                          />
                          <Route
                            path="/list3"
                            render={() => (
                              <AsyncWip
                                title="Товары"
                                subtitle="Продажи, возвраты и перемещения"
                              />
                            )}
                          />
                          <Route path="/notices" component={AsyncNotices} />
                          <Route path="/search" component={AsyncSearchPage} />
                          <Route
                            path="/client/:type/:id"
                            render={(loc) => (
                              <MobileNavBar {...loc} routing={routingClient}>
                                <Switch>
                                  <Route
                                    path="/client/fitting/:id"
                                    component={AsyncClient}
                                  />
                                  <Route
                                    path="/client/fitting2/:id"
                                    render={() => <AsyncWip title="Покупки" />}
                                  />
                                  <Route
                                    path="/client/fitting3/:id"
                                    render={() => <AsyncWip title="Бонусы" />}
                                  />
                                  <Route
                                    path="/client/fitting4/:id"
                                    render={() => <AsyncWip title="Возвраты" />}
                                  />
                                </Switch>
                              </MobileNavBar>
                            )}
                          />
                        </Switch>
                      </MobileNavBar>
                    </AsyncMainPage>
                  )}
                />
              )}
              <Route
                path="/"
                render={() => (
                  <AsyncMainPage>
                    <MainMenu access={user.access} />
                  </AsyncMainPage>
                )}
              />
            </Switch>
          </>
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
