import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import styles from './MobileNavBar.module.css';

MobileNavBar.propTypes = {
  routing: PropTypes.instanceOf(Array).isRequired,
  children: PropTypes.node.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

export default function MobileNavBar({ routing, children, match, ...props }) {
  const { noticeBadges, theme } = useSelector((state) => state.system);

  if (
    routing.findIndex(
      (item) =>
        (item.link ? item.link(match.params) : item.route) === match.url,
    ) < 0
  ) {
    return children;
  }

  return (
    <div className={styles.box}>
      {children}
      <div
        className={styles.container}
        style={{ 
          background: theme === 'light' ? '#eee' : '#1b1b1b',
          color: theme === 'light' ? '#1b1b1b' : '#eee',
        }}>
        {routing.map((item) => (
          <NavLink
            to={item.link ? item.link(match.params) : item.route}
            key={item.route}
            className={styles.link}
            activeClassName={styles.linkActive}>
            <Badge dot={noticeBadges[item.name]}>
              {item.icon}
              <span className={styles.title}>{item.title}</span>
            </Badge>
          </NavLink>
        ))}
      </div>
    </div>
  );
}
