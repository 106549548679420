import React, { useEffect, useRef } from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import message from 'antd/lib/message';
import { useSelector, useDispatch } from 'react-redux';
import { getNoticeBadges } from '../../pages/main/main.action';

const openNotification = ({ updateCb, btnTitle, text, description }) => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        notification.close(key);
        updateCb();
      }}>
      {btnTitle}
    </Button>
  );
  notification.open({
    message: text,
    description,
    btn,
    key,
    placement: 'bottomRight',
    bottom: 10,
    duration: 0,
  });
};

export default function NotificationSW() {
  const dispatch = useDispatch();

  const deferredPrompt = useRef(null);

  const isServiceWorkerInitialized = useSelector(
    (state) => state.system.serviceWorkerInitialized,
  );
  const isServiceWorkerUpdated = useSelector(
    (state) => state.system.serviceWorkerUpdated,
  );
  const serviceWorkerRegistration = useSelector(
    (state) => state.system.serviceWorkerRegistration,
  );

  useEffect(() => {
    dispatch(getNoticeBadges());
    const timer = setInterval(() => dispatch(getNoticeBadges()), 60000);

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      deferredPrompt.current = e;
      setTimeout(
        () =>
          openNotification({
            updateCb: addIconToDesktop,
            btnTitle: 'добавить',
            text: 'Добавить иконку на раб. стол',
            description:
              'Для более удобного использования приложением добавьте его на рабочий стол.',
          }),
        3000,
      );
    });

    return () => clearInterval(timer);
  }, [dispatch]);

  function addIconToDesktop() {
    deferredPrompt.current.prompt();

    // Wait for the user to respond to the prompt
    deferredPrompt.current.userChoice.then((resp) => {
      console.info(JSON.stringify(resp));
    });
  }

  function updateServiceWorker() {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }

  if (isServiceWorkerUpdated) {
    openNotification({
      updateCb: updateServiceWorker,
      btnTitle: 'обновить',
      message: 'Доступна новая версия',
      description: 'Нажмите обновить, чтобы получить новую версию.',
    });
  }

  if (isServiceWorkerInitialized) {
    message.success('Данные успешно сохранены для оффлайн режима');
  }

  return null;
}
