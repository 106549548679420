import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: '',
};

const clients = createSlice({
  name: 'client',
  initialState,
  reducers: {
    getClientStart(state) {
      state.loading = true;
    },
    getClientSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateFittingCard(state, action) {
      const index = state.data.fittingCards.findIndex(
        (item) => item.requestId === action.payload.requestId,
      );
      state.data.fittingCards[index] = action.payload;
    },
    getClientError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getClientStart,
  getClientSuccess,
  getClientError,
  updateFittingCard,
} = clients.actions;

export default clients.reducer;

/**
 *
 * @param {string} clientId client Id
 */
export const getClient = (clientId) => async (dispatch, getStore, core) => {
  try {
    dispatch(getClientStart());
    const result = await core.api.get(`/client/fitting/${clientId}`);

    dispatch(getClientSuccess(result));
  } catch (err) {
    dispatch(getClientError(err));
  }
};

/**
 *
 * @param {string} targetName FittingCard | FittingProduct | FittingReserve
 * @param {string} targetId requestId | productId
 * @param {string} actionName FittingPaid | RejectInShop | ProductSold | ProductReject | ProductMoveToShop | ProductMoveToStore | ReserveConfirm | ReserveReject | FittingReserveConfirm |  FittingReserveReject
 * @param {object} data params from action
 */
export const setAction = (targetName, targetId, actionName, data) => async (
  dispatch,
  getStore,
  core,
) => {
  try {
    const result = await core.api.post(
      `/process/${targetName}/${targetId}/${actionName}`,
      data,
    );

    core.notification.success({
      message: 'Успех!',
      description: 'Действие успешно выполнено!',
      placement: 'topLeft',
    });

    dispatch(updateFittingCard(result));

    return result;
  } catch (error) {
    console.error(error);
  }
};
