import React from 'react';
import PropTypes from 'prop-types';
import {
  UsergroupAddOutlined,
  CarryOutOutlined,
  SkinOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Badge, Card } from 'antd';
import { useSelector } from 'react-redux';
import HeaderPortal from '../header/HeaderPortal';
import styles from './mainMenu.module.css';
import CameraLoader from '../../features/cameraLoader/CameraLoader';

const { Meta } = Card;

const TileButton = ({ title, description, badge, icon: Icon, to, children }) => to  ? (
  <Link className={styles.link} to={to}>
    <Card
      className={styles.card}
      cover={
        <span>
          <Badge dot={badge}>
            <Icon className={styles.icon} />
          </Badge>
        </span>
      }>
      <Meta title={title} description={description} />
    </Card>
  </Link>
) : (
  <div className={styles.link}>
    <Card
      className={styles.card}
      cover={
        <span>
          <Badge dot={badge}>
            <Icon className={styles.icon} />
          </Badge>
        </span>
      }>
      <Meta title={title} description={description} />
      {children}
    </Card>
  </div>
);

TileButton.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  badge: PropTypes.bool,
  icon: PropTypes.elementType,
  to: PropTypes.string.isRequired,
};

TileButton.defaultProps = {
  badge: false,
  icon: null,
};

export default function MainMenu(props) {
  const noticeBadges = useSelector((state) => state.system.noticeBadges);

  return (
    <div className={styles.container}>
      <HeaderPortal access={props.access} title="Gepur" />

      {props.access['Магазины'] === 15 && (
        <>
          <TileButton
            icon={UsergroupAddOutlined}
            to="/list"
            title="Клиенты"
            description="Список клиентов / примерок / бонусов"
          />

          <TileButton
            icon={BellOutlined}
            to="/notices"
            title="Нотис"
            badge={noticeBadges.notice}
            description="Уведомления о брони и другие оповещения"
          />

          <TileButton
            icon={SkinOutlined}
            to="/list"
            title="Товары"
            description="Наличие, продажи и перемещения товаров"
          />

          <TileButton
            icon={CarryOutOutlined}
            to="/list"
            title="Заказы"
            description="Приемка заказов магазина"
          />
        </>
      )}

      {props.access['Съёмка'] === 15 && (
        <>
          <CameraLoader />
          <CameraLoader camera />
        </>
      )}
    </div>
  );
}
