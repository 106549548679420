/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  limit: 10,
  offset: 0,
  sort: 'ASC',
  loading: false,
  loadingScroll: false,
  error: null,
  totalCount: 0,
};

const notices = createSlice({
  name: 'notices',
  initialState,
  reducers: {
    getNoticeStart(state) {
      state.loading = true;
    },
    getNoticeSuccess(state, action) {
      state.data = action.payload.data;
      state.loading = false;
      state.limit = action.payload.limit;
      state.offset = action.payload.data.length;
      state.error = null;
      state.totalCount = action.payload.totalCount;
    },
    updateNoticeCard(state, action) {
      const index = state.data.findIndex(
        (item) => item.id === action.payload.id,
      );
      state.data[index] = action.payload;
    },
    getNoticeError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    pushList(state, action) {
      state.data = [...state.data, ...action.payload];
      state.offset += action.payload.length;
      state.loadingScroll = false;
      state.loading = false;
    },
    getListLoading(state) {
      state.loadingScroll = true;
    },
  },
});

export const {
  getNoticeStart,
  getNoticeSuccess,
  updateNoticeCard,
  getNoticeError,
  pushList,
  getListLoading,
} = notices.actions;

export default notices.reducer;

export const getNoticesList = (limit = 10, offset = 0, sort) => async (
  dispatch,
  getState,
  core,
) => {
  try {
    dispatch(getNoticeStart());

    const [data, { count }] = await Promise.all([
      core.api.get(`/notice/all?limit=${limit}&offset=${offset}`),
      core.api.get('/notice/all-count'),
    ]);

    dispatch(
      getNoticeSuccess({
        data,
        limit,
        offset,
        sort,
        totalCount: count,
      }),
    );
  } catch (err) {
    dispatch(getNoticeError(err));
  }
};

export const getNextPageNoticeList = () => async (dispatch, getState, core) => {
  try {
    const { limit, offset, totalCount } = getState().notices;
    dispatch(getListLoading());

    const result = await core.api.get(
      `/notice/all?limit=${limit}&offset=${offset}`,
    );

    if (totalCount < offset + result.length) {
      core.message.success('весь список загружен');
    }

    dispatch(pushList(result));
  } catch (err) {
    dispatch(getNoticeError(err));
  }
};
